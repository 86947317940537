import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AshGuidesEventVoy: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Voyagers and Everlasting Shadows</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_event_1.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Voyagers and Everlasting Shadows Event Guide</h1>
          <h2>
            A guide for the first event available in Ash Echoes Global version -
            Voyagers and Everlasting Shadows.
          </h2>
          <p>
            Last updated: <strong>09/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Overview" />
        <StaticImage
          src="../../../images/ash/event/voyager_1.webp"
          alt="Guides"
        />
        <p>Welcome to Senlo! Are you ready for a spooky night at the Museum?</p>
        <p>
          This first Event follows the adventures of the Director and Cen Ying
          at the Museum, trying to elucidate a mystery blurring the lines of
          reality. You’ll have access to a Main Story, punctuated by fights
          where the Nexus Builds are already premade for you, and the fights
          will feature some event special gimmicks to spice things up a bit.
        </p>
        <p>
          There will be many quests to complete, including the 3 Acts of the
          Story, Side Missions in the forms of Investigations, a Tower Defense
          Mini-game, as well as the Boss Challenge which we’ll cover in its own
          dedicated page. By roaming around the Event Map you’ll also find
          multiple collectibles to fill an Album with, a nice addition for
          collectors.
        </p>
        <p>
          Oh! And in case you haven’t noticed yet, the Event Screen is
          interactable, so have fun poking at it!
        </p>
        <SectionHeader title="The Shop" />
        <StaticImage
          src="../../../images/ash/event/voyager_2.webp"
          alt="Guides"
        />
        <p>Welcome to the Souvenir Shop!</p>
        <p>
          A wide range of options are available, and an ample amount of Event
          Currency can be obtained simply by playing through the various modes
          of the Event.{' '}
          <strong>
            The most precious items are the SSR Memory Trace ‘Traces In The
            Museum’ and its dupes
          </strong>{' '}
          - you’ll be able to max it just by completing the story and engaging
          with a few side quests.
        </p>
        <p>After that, it’s up to you:</p>
        <ul>
          <li>
            If you enjoy collecting furniture, interacting with your Echomancers
            and decorating your Lounge, make sure to grab all the furniture from
            the Museum artifacts collection.
          </li>
          <li>
            After that come the Summoning Currencies, the Resonance Clues and
            the Momentary Backflash.
          </li>
        </ul>
        <p>
          All of the above can be obtained by completing at least one Event
          content daily,, clearing the Story, beating the Mini-game and
          progressing a bit through the Boss Challenge without having to go too
          high in the difficulty.
        </p>
        <p>
          After that you can start picking up upgrade materials for your
          Echomancers, Coins or Mirrorshards (which are used to level up Memory
          Traces). Here the choice is yours, you can try to get as many of the
          higher rarity mats or focus on those that are useful to your Main
          Team, giving you a small but noticeable boost in power fairly quickly
          to start engaging with the more challenging flights in the game.
        </p>
        <p>
          You can also pick the materials that immediately help you progress
          with the Phase Quests (like upgrading multiple Skills etc…).
        </p>
        <SectionHeader title="Museum Defense" />
        <StaticImage
          src="../../../images/ash/event/voyager_3.webp"
          alt="Guides"
        />
        <p>
          A Tower Defense Mini-game, Museum Defense adds a little Reaction
          Synergy twist to the genre. Two paths can be completed, one playing
          around the Explosion synergy and the other around both Freeze and
          Electroconduct. To access it, you can tap the Museum Defense icon on
          the bottom right of the screen once you are in the Event Map and have
          completed the Event Story beforehand.
        </p>
        <p>
          On top of the spell cards, you will have access to an EMP ability that
          charges up overtime. Whenever you tap/click to use this ability, it
          deals damage equal to a certain amount of the enemies’ Max HP%
          corresponding to the charged amount. Aside from the final Boss, any
          enemy can be one shot if you use the ability at 100% charge regardless
          of their toughness.
        </p>
        <p>
          You’ll earn a few new abilities as you make your way towards the later
          stages and you’ll be able to make some pretty cool interactions happen
          if you play your cards right (pun intended).
        </p>
        <p>
          Even if you’re not comfortable with Tower Defense Mini-games, we
          recommend that you play this one as it doesn’t delve deep into
          complexity and remains a fun and quick way to obtain some more Event
          currency.
        </p>
        <SectionHeader title="Stress Test" />
        <StaticImage
          src="../../../images/ash/event/voyager_4.webp"
          alt="Guides"
        />
        <p>
          The last type of Mini-challenges the Event has to offer is Stress
          Test. These stages are fairly difficult encounters and similarly to
          Museum Defense, you can access them from the Event Map once you’ve
          completed the Event Story. Each of them gives a Momentary Backflash,
          so don’t forget to visit them for more of the good stuff!
        </p>
        <p>
          Bringing a mix of sturdy Defense and multiple AoE DPS Echomancers is
          the key here as the enemies can very easily overwhelm you from all
          sides.
        </p>
        <p>
          The conditions must be respected, otherwise it’s Game Over. To help
          you better prepare, you can check the details of each mission, showing
          the strengths and weaknesses of each enemy.
        </p>
        <SectionHeader title="Boss Challenge" />
        <p>
          The Boss Challenge guide for this event can be found in a separate
          guide - we had to split it due to its massive size. You can find it
          here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Boss Challenge: Sephonis"
            link="/ash-echoes/guides/boss-challenge-sephonis"
            image={
              <StaticImage
                src="../../../images/ash/categories/category_event_2.webp"
                alt="Boss Challenge: Sephonis"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesEventVoy;

export const Head: React.FC = () => (
  <Seo
    title="Voyagers and Everlasting Shadows | Ash Echoes | Prydwen Institute"
    description="A guide for the first event available in Ash Echoes Global version."
    game="ash"
  />
);
